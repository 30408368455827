import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';

interface ISellerPhoto {
    nameOfAdvertiser: string;
}

export const NameOfAdvertiser: FC<ISellerPhoto> = ({ nameOfAdvertiser }) => {
    return <Box>
        <Text
            noWrap
            semibold
            variant='breadcrumb'
            color={(palette) => palette.labelSecondary}
        >
            {nameOfAdvertiser}
        </Text>
    </Box>;
};
