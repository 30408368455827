import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';

interface IPhotoWrapper {
    children: ReactNode;
    height: number | string
}

export const PhotoWrapper: FC<IPhotoWrapper> = ({ children, height }) => {
    return <Box
        width='100%'
        height={height}
        display='flex'
        borderRadius={2}
        overflow='hidden'
        position='relative'
        alignItems='center'
        justifyContent='center'
        bgcolor={(theme) => theme.palette.backgroundSecondary.main}
    >
        {children}
    </Box>;
};
