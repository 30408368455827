import { FC } from 'react';
import { Stack } from '@mui/material';
import {
    Price
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/price/Price';
import {
    Information
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/information/Information';
import { ESubcategory } from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ESubcategory';

interface IText {
    price: string;
    area?: number;
    location: string;
    unitPrice?: string;
    subcategory: ESubcategory,
}

export const Text: FC<IText> = ({
    area,
    price,
    location,
    unitPrice,
    subcategory,
}) => {
    return <Stack direction='column' gap={1} sx={{ maxWidth: 'calc(100% - 50px)' }}>
        <Price
            isMobile
            price={price}
            unitPrice={unitPrice}
        />
        <Information
            area={area}
            location={location}
            subcategory={subcategory}
        />
    </Stack>;
};
