import { FC } from 'react';
import { Box, useTheme } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { EBadgeType } from 'modules/theme/components/listing/cards/advertisementCard/enums/badge/EBadgeType';
import { BADGE_TYPE_AND_STYLE_RECORD } from 'modules/theme/components/listing/cards/advertisementCard/constants/badge/BadgeTypeAndStyleRecord';

interface IBadge {
    type: EBadgeType;
}

export const Badge: FC<IBadge> = ({ type }) => {
    const { palette } = useTheme();

    const { color, bgColor } = BADGE_TYPE_AND_STYLE_RECORD(palette)[type];

    return (
        <Box
            paddingX={0.75}
            paddingY={0.14}
            borderRadius='999px'
            sx={{
                background: bgColor,
                '& .MuiTypography-root': {
                    lineHeight: '15px'
                }
            }}
        >
            <Text variant='breadcrumb' semibold color={color}>
                {type}
            </Text>
        </Box>
    );
};
