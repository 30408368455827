import { FC } from 'react';
import { User as UserPhoto } from 'modules/theme/components/assets/userBudges/user/User';

interface IAgentPhoto {
    width: number;
    isPro?: boolean;
    badgeRight?: boolean;
    badgeLeft?: boolean;
    previewPhotoUrl?: string;
    alt?: string;
    title?: string;
}

export const AgentPhoto: FC<IAgentPhoto> = ({ isPro, width, previewPhotoUrl, badgeLeft, badgeRight, alt, title }) => {
    return <UserPhoto
        badge={isPro}
        width={width}
        photoUrl={previewPhotoUrl}
        badgeLeft={isPro && badgeLeft}
        badgeRight={isPro && badgeRight}
        alt={alt}
        title={title}
    />;
};
