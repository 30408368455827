import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';


interface IDescription {
    showMoreText?: boolean;
    description: string;
}

export const Description: FC<IDescription> = ({ description, showMoreText }) => {

    return <Text variant='body2' lineClamp={showMoreText ? 3 : 1}>
        {description}
    </Text>;
};
