import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

interface IWrapper {
    children: ReactNode;
}

export const Wrapper: FC<IWrapper> = ({ children }) => {
    return <Box
        top={16}
        left={16}
        zIndex={2}
        right={16}
        bottom={16}
        display='flex'
        position='absolute'
        flexDirection='column'
        justifyContent='space-between'
    >
        {children}
    </Box>;

};
