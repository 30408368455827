import { FC } from 'react';
import {
    ESubcategory
} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ESubcategory';
import {
    ParameterWrapper
} from 'modules/theme/components/listing/cards/common/components/parameters/common/ParameterWrapper';
import {
    Area
} from 'modules/theme/components/listing/cards/common/components/parameters/Area';
import {
    Subcategory
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/information/parameters/partials/Subcategory';

interface Iparameters {
    area?: number,
    subcategory: ESubcategory,
}

export const Parameters: FC<Iparameters> = ({ subcategory, area }) => {
    return <ParameterWrapper gap={2}>
        <Subcategory variant='body3' subcategory={subcategory}/>
        {!!area && <Area variant='body3' value={area}/>}
    </ParameterWrapper>;
};
