import { FC } from 'react';
import Image from 'components/image/Image';
import { Figure } from 'modules/theme/components/assets/blankPlaceholder/Figure';
import {
    PhotoWrapper
} from 'modules/theme/components/listing/cards/common/components/photoItem/partials/photoWrapper/PhotoWrapper';

interface IPhotoItem {
    photoUrl?: string;
    height: number;
    width: number;
    alt?: string;
    title?: string;
    priorityLoad?: boolean;
    blurDataURL?: string;
}

export const PhotoItem: FC<IPhotoItem> = ({ photoUrl, height, width, alt, title, priorityLoad, blurDataURL }) => {

    return (
        <PhotoWrapper height={height}>
            {photoUrl ? (
                <Image
                    height={height}
                    width={width}
                    src={photoUrl}
                    priority={priorityLoad}
                    alt={alt || 'Fotka inzerátu'}
                    title={title}
                    sizes='100vw'
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                    placeholder={blurDataURL ? 'blur' : 'empty'}
                    blurDataURL={blurDataURL}
                />
            ) : (
                <Figure width={40} />
            )}
        </PhotoWrapper>
    );
};
