import { FC } from 'react';
import { Grid } from '@mui/material';
import {
    ThumbPhoto
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/photoPart/thumbs/partials/ThumbPhoto';

interface IThumbPhotos {
    photosUrl: string[];
    priorityLoad?: boolean;
}

export const ThumbPhotos: FC<IThumbPhotos> = ({ photosUrl, priorityLoad }) => {
    const photos = [];
    for (let i = 0; i < 3; i++) photos.push(
        <ThumbPhoto key={i} photoUrl={photosUrl[i] || null} priorityLoad={priorityLoad}/>
    );


    return <Grid container height={84} display='flex' columnSpacing={.5}>

        {photos.map((photo, index) =>
            <Grid xs={4} item key={index}>
                {photo}
            </Grid>
        )}

    </Grid>;
};
