import { FC } from 'react';
import { Stack } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import Video from 'modules/theme/components/icons/basic/Video.svg';
import Object3D from 'modules/theme/components/icons/basic/Object3D.svg';
import FloorPlan from 'modules/theme/components/icons/technical/FloorPlan.svg';
import {
    AttachmentWrapper
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/photoPart/preview/partials/badges/partials/bottom/partials/attachments/partials/AttachmentWrapper';


interface IAttachmentsBadges {
    has3d?: boolean;
    hasVideo?: boolean;
    hasFloowPlan?: boolean;
}

export const AttachmentsBadges: FC<IAttachmentsBadges> = ({ hasVideo, has3d, hasFloowPlan }) => {
    return <Stack direction='row' gap={1}>

        {hasVideo &&
            <AttachmentWrapper>
                <Svg component={Video} width={16} height={16}/>
            </AttachmentWrapper>
        }

        {has3d &&
            <AttachmentWrapper>
                <Svg component={Object3D} width={16} height={16}/>
            </AttachmentWrapper>
        }

        {hasFloowPlan &&
            <AttachmentWrapper>
                <Svg component={FloorPlan} width={16} height={16}/>
            </AttachmentWrapper>
        }

    </Stack>;
};
