import { FC } from 'react';
import { EBadgeType } from 'modules/theme/components/listing/cards/advertisementCard/enums/badge/EBadgeType';
import {
    Badges as CommonBadges
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/badge/Badges';

interface IPackageBadges {
    isTop?: boolean;
    isBasic?: boolean;
    isPremium?: boolean;
}

export const PackageBadges: FC<IPackageBadges> = ({ isTop, isBasic, isPremium }) => {

    const badges: EBadgeType[] = [];
    if (isPremium) badges.push(EBadgeType.PREMIUM);
    if (isBasic) badges.push(EBadgeType.BASIC);
    if (isTop) badges.push(EBadgeType.TOP);

    return <CommonBadges types={badges}/>;
};
