import { FC, ReactNode } from 'react';
import Link from 'components/link/Link';

interface ILinkWrapper {
    children: ReactNode;
    detailUrl: string;
    onClick?: () => void;
    openInNewTab?: boolean;
}

export const LinkWrapper: FC<ILinkWrapper> = ({ detailUrl, onClick, children, openInNewTab }) => {

    return <Link
        onClick={onClick}
        href={detailUrl}
        sx={{
            zIndex: 1,
            color: 'inherit',
            textDecoration: 'none'
        }}
        openInNewTab={openInNewTab}
    >
        {children}
    </Link>;
};
