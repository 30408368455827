import { FC } from 'react';
import AreaIcon from 'modules/theme/components/icons/technical/Area.svg';
import {
    Parameter
} from 'modules/theme/components/listing/cards/common/components/parameters/common/Parameter';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';


interface IArea {
    value: number;
    variant: OverridableStringUnion<Variant, TypographyPropsVariantOverrides>;
}

export const Area: FC<IArea> = ({ value, variant }) => {
    return <Parameter variant={variant} value={`${value} m²`} icon={AreaIcon} shrink/>;
};
