import { FC } from 'react';
import { Stack } from '@mui/system';
import { Text } from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/mobile/partials/text/Text';
import { ESubcategory } from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ESubcategory';
import { SellerPhoto } from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/seller/SellerPhoto';
import { DevProjectLink } from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/devProject/DevProjectLink';
import {
    IDevProject
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/devProject/IDevProject';
import Link from 'components/link/Link';
interface IContent {
    price: string;
    area?: number;
    location: string;
    detailUrl: string;
    unitPrice?: string;
    onClick: () => void;
    isSellerPro?: boolean;
    sellerPhotoUrl?: string;
    devProject?: IDevProject;
    subcategory: ESubcategory;
}

export const Content: FC<IContent> = ({
    area,
    price,
    onClick,
    location,
    unitPrice,
    detailUrl,
    devProject,
    subcategory,
    isSellerPro,
    sellerPhotoUrl,
}) => {
    return (
        <Stack direction='column' gap={0.5} position='relative'>
            <Link
                openInNewTab={true}
                href={detailUrl}
                onClick={onClick}
                sx={{
                    top: 0,
                    left: 0,
                    height: 75,
                    width: '100%',
                    position: 'absolute'
                }}
            >&nbsp;</Link>

            <Stack
                width='100%'
                direction='row'
                flexWrap='wrap'
                justifyContent='space-between'
            >
                <Text
                    area={area}
                    price={price}
                    location={location}
                    unitPrice={unitPrice}
                    subcategory={subcategory}
                />

                <SellerPhoto photoUrl={sellerPhotoUrl} isPro={isSellerPro} />
            </Stack>
            {devProject && (
                <DevProjectLink devProject={devProject}/>
            )}
        </Stack>
    );
};
